<template>
  <Navbar v-if="showNav" />
  <router-view/>
</template>

<script>
import { mapState } from 'vuex'
import Navbar from './components/Navbar'
export default {
  components: {
    Navbar
  },
  computed: {
    ...mapState(['userProfile']),
    showNav () {
      return this.userProfile !== null && this.$route.path !== '/' && this.$route.path !== '/login' && this.$route.path !== '/signup' && this.$route.path !== '/help' && this.$route.path !== '/terms' && this.$route.path !== '/privacy'
    }
  }
}
</script>
