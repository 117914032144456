<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!-- When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars -->
  <Popover as="template" v-slot="{ open }">
    <header :class="[open ? 'fixed inset-0 z-40 overflow-y-auto' : '', 'md:py-4 lg:py-0 bg-white shadow-sm lg:static lg:overflow-y-visible']">
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8 py-4">
          <div class="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-8">
            <div class="flex-shrink-0 flex items-center">
              <router-link to="/app">
                <img class="block h-10 w-auto" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630871487/loops_icon_n9ebrj.svg" alt="Workflow" />
              </router-link>
            </div>
          </div>
          <div class="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
            <!-- Mobile menu button -->
            <PopoverButton class="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="sr-only">Open menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <div class="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">

            <!-- Profile dropdown -->
            <Menu as="div" class="flex-shrink-0 relative ml-5">
              <div>
                <MenuButton class="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Open user menu</span>
                  <div class="bg-gray-50 text-gray-800 rounded-full w-10 h-10 inline-block flex items-center justify-center focus:outline-indigo-500 focus:shadow-solid">
                    {{ userAvatar }}
                  </div>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <router-link to='/app/account' :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">Settings</router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link to='/help' :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">Help</router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700 hover:pointer']" @click.prevent="logout">Sign Out</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <PopoverPanel as="nav" class="lg:hidden" aria-label="Global">
        <!-- <div class="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
          <a v-for="item in navigation" :key="item.name" :href="item.href" :aria-current="item.current ? 'page' : undefined" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50', 'block rounded-md py-2 px-3 text-base font-medium']">{{ item.name }}</a>
        </div> -->
        <div class="border-t border-gray-200 pt-4 pb-3">
          <div class="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
            <div class="flex-shrink-0">
              <div class="bg-gray-50 text-gray-800 rounded-full w-10 h-10 inline-block flex items-center justify-center focus:outline-none focus:shadow-solid">
                {{ userAvatar }}
              </div>
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">{{ userProfile.name }}</div>
              <div class="text-sm font-medium text-gray-500">{{ currentUser.email }}</div>
            </div>
           <!--  <button type="button" class="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->
          </div>
          <div class="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
            <router-link to='/app/account' class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Settings</router-link>
            <router-link to='/help' class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Help</router-link>
            <a class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900 hover:pointer" @click.prevent="logout">Sign Out</a>
          </div>
        </div>
      </PopoverPanel>
    </header>
  </Popover>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
// import { SearchIcon } from '@heroicons/vue/solid'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverPanel,
    // BellIcon,
    MenuIcon,
    XIcon
  },
  data () {
    return {
      searchText: ''
    }
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'userAccounts']),
    userAvatar: function () {
      // `this` points to the vm instance
      let names
      let initials
      if (this.userProfile) {
        names = this.userProfile.name.split(' ')
        initials = names[0].substring(0, 1).toUpperCase()
      }
      if (names && names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }
  },
  methods: {
    handleSearch () {
      this.$emit('searchInput', this.searchText)
    },
    logout () {
      window.analytics.track('Signed Out')
      fb.auth.signOut().then(() => {
        this.$store.dispatch('clearData')
        this.$router.push('/login')
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
