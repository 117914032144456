import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

firebase.initializeApp(config)

if (location.hostname === 'localhost') {
  firebase.firestore().useEmulator('localhost', 8080)
  firebase.auth().useEmulator('http://localhost:9099/')
  firebase.functions().useEmulator('localhost', 5001)
}

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.app().functions(process.env.VUE_APP_FUNCTION_LOCATION)
const currentUser = auth.currentUser

// firebase collections
const usersCollection = db.collection('users')
const accountsCollection = db.collection('accounts')
const notificationsCollection = db.collection('notifications')

export {
  db,
  auth,
  functions,
  currentUser,
  usersCollection,
  accountsCollection,
  notificationsCollection
}
