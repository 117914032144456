<template>
  <div id="dashboard" class="h-screen flex overflow-hidden bg-gray-50">
    <div class="flex-1 overflow-auto focus:outline-none" tabindex="0">
      <TransitionRoot as="template" :show="isOpen" v-if="!responses || !responses.length" v-cloak>
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" :open="isOpen" @close="setIsOpen(!isOpen)">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="setIsOpen(!isOpen)">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" class="text-xl leading-6 font-medium text-gray-900">
                      Get Started with Short Loops
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Integrate the widget into your website to start collecting customer feedback. If you have any troubles reach out anytime, we're here to help!
                      </p>
                    </div>

                    <p class="text-sm text-gray-500 mt-4">To embed the feedback widget on your website, first add the script tag with your project id to the HTML:</p>

                    <div class="bg-gray-50 py-2 px-4 my-4 rounded overflow-x-scroll">
                      <code class="text-xs leading-none ">
                        &lt;script&gt;<br />
                          <span class="pl-4">(<span class="text-blue-400">function</span> (<span class="text-yellow-500">w</span>,<span class="text-yellow-500">d</span>,<span class="text-yellow-500">s</span>,<span class="text-yellow-500">o</span>,<span class="text-yellow-500">f</span>,<span class="text-yellow-500">js</span>,<span class="text-yellow-500">fjs</span>) {
                          <span class="no-wrap whitespace-nowrap pl-4">w['<span class="text-yellow-200">JS-Widget</span>']=o;w[o] = w[o] || <span class="text-blue-400">function</span> () { (w[o].q = w[o].q || []).<span class="text-blue-400">push</span>(arguments) };</span>
                              <span class="no-wrap whitespace-nowrap pl-4">js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];</span>
                              <span class="no-wrap whitespace-nowrap pl-4">js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);</span>
                            <span class="no-wrap whitespace-nowrap pl-4">}(window, document, 'script', '_hw', '{{ widgetUrl }}' ));</span><br />
                          <span class="no-wrap whitespace-nowrap pl-4"><span class="text-blue-400">_hw</span>('init', { minimized: true, appId: '{{currentAccount.appId}}' });</span><br />
                          </span>

                      &lt;/script&gt;
                      </code>
                    </div>

                    <div class="relative">
                      <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300" />
                      </div>
                      <div class="relative flex justify-center">
                        <span class="px-2 bg-white text-sm text-gray-500">
                          Or
                        </span>
                      </div>
                     </div>
                  </div>
                  <div class="px-4 py-5 sm:p-6">
                    <h4 class="text-lg leading-6 font-medium text-gray-900">
                      Need setup support?
                    </h4>
                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                      <p>
                        You can send these instructions to a developer on your team.
                      </p>
                    </div>
                    <form @submit.prevent class="mt-5 sm:flex sm:items-center">
                      <div class="w-full sm:max-w-xs">
                        <label for="email" class="sr-only">Email</label>
                        <input v-model="email" type="email" name="email" id="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                      </div>
                      <button @click="sendEmail" type="submit" class="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Send
                      </button>
                    </form>
                    <transition name="fade">
                      <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mt-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <!-- Heroicon name: solid/check-circle -->
                            <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                            </svg>
                          </div>
                          <div class="ml-3">
                            <p class="text-sm font-medium text-green-800">
                              Email sent successfully
                            </p>
                          </div>
                        </div>
                      </div>
                    </transition>
                    <transition name="fade">
                      <div v-if="errorMsg !== ''" class="bg-red-50 border-l-4 border-red-400 p-4 mt-4">
                        <div class="flex">
                          <div class="flex-shrink-0">
                            <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
                            </svg>
                          </div>
                          <div class="ml-3">
                            <p class="text-sm leading-5 text-red-700">
                              {{ errorMsg }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>

                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
      <main class="flex-1 relative pb-8 z-0 overflow-y-auto">
        <!-- Page header -->
        <div class="bg-white shadow">
          <div class="px-4 sm:px-6 lg:max-w-5xl lg:mx-auto lg:px-8">
            <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div class="flex-1 min-w-0">
                <!-- Profile -->
                <div class="flex items-center">
                  <div>
                    <div class="flex items-center">
                      <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                        {{ greeting }}, {{userProfile ? userProfile.name : ''}}
                      </h1>
                    </div>
                    <div v-if="!responses || !responses.length" class="rounded-md bg-blue-50 p-4 mt-2">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                          <p class="text-sm text-blue-700">
                            Integrate the widget in your website or app and start collecting customer feedback.
                          </p>
                          <p class="mt-3 text-sm md:mt-0 md:ml-6">
                            <button @click="isOpen = true" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">Install <span aria-hidden="true">&rarr;</span></button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="py-10">
        <div class="max-w-3xl px-4 mx-auto lg:max-w-5xl sm:px-6 lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
        <div class="hidden lg:block lg:col-span-3">
          <nav aria-label="Sidebar" class="sticky top-4 divide-y divide-gray-300">
            <div class="pt-6">
              <p class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="communities-headline">
                Filters
              </p>
              <div class="mt-3 space-y-2" aria-labelledby="communities-headline">
                <button class="group flex items-center justify-between px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-200 bg-gray-100 w-full">
                  <div class="flex items-center">
                    <svg class="-ml-1 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    <span class="truncate">
                      All
                    </span>
                  </div>
                  <div>{{ responses && responses.length ? responses.length : 1 }}</div>
                </button>
                <!-- <button class="group flex items-center justify-between px-3 py-2 text-sm font-medium text-green-600 rounded-md hover:text-green-900 hover:bg-green-200 bg-green-50 w-full">
                  <div class="flex items-center">
                    <svg class="-ml-1 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    <span class="truncate">
                      Positive
                    </span>
                  </div>
                  <div>{{ responses && responses.length ? responses.length : 1 }}</div>
                </button>
                <button class="group flex items-center justify-between px-3 py-2 text-sm font-medium text-yellow-600 rounded-md hover:text-yellow-900 hover:bg-yellow-200 bg-yellow-50 w-full">
                  <div class="flex items-center">
                    <svg class="-ml-1 mr-1.5 h-2 w-2 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    <span class="truncate">
                      Neutral
                    </span>
                  </div>
                  <div>{{ responses && responses.length ? responses.length : 1 }}</div>
                </button>
                <button class="group flex items-center justify-between px-3 py-2 text-sm font-medium text-red-600 rounded-md hover:text-red-900 hover:bg-red-200 bg-red-50 w-full">
                  <div class="flex items-center">
                    <svg class="-ml-1 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    <span class="truncate">
                      Negative
                    </span>
                  </div>
                  <div>{{ responses && responses.length ? responses.length : 1 }}</div>
                </button> -->
              </div>
            </div>
          </nav>
        </div>

          <div class="lg:col-span-9 mt-4">
            <h1 class="sr-only">Recent responses</h1>
            <ul role="list" class="space-y-4"> <!-- v-if="responses && responses.length" -->
              <li v-for="response in filteredResponses" :key="response.id" class="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
                <article :aria-labelledby="'question-title-' + response.id">
                  <div>
                    <div class="flex space-x-3 items-center">
                      <div class="min-w-0 flex-1">
                        <span :class="[statusStyles[response.sentiment.label ? response.sentiment.label : 'uncategorized'], 'inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium capitalize']">
                          {{ response.sentiment.label ? response.sentiment.label : 'uncategorized' }}
                        </span>
                      </div>
                      <p class="text-sm text-gray-500">
                        <time :datetime="response.created">{{ moment(response.created).fromNow() }}</time>
                      </p>
                    </div>
                   </div>
                  <div class="mt-4 text-sm text-gray-700 space-y-4" v-html="response.text" />
                  <Disclosure>
                    <DisclosureButton class="pt-4 text-xs text-indigo-500">
                      Show Details
                    </DisclosureButton>
                    <DisclosurePanel class="text-gray-500">
                      <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 mt-4">
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            User
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 font-medium">
                            {{ response.userid ? response.userid : '--'}}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Browser
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 font-medium">
                            {{ response.browserData ? setDeviceData(response.browserData) : '--' }}
                          </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-medium text-gray-500">
                            Page
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 font-medium">
                            {{ response.routeData ? response.routeData.replace(/(^\w+:|^)\/\//, '') : '--' }}
                          </dd>
                        </div>
                      </dl>
                    </DisclosurePanel>
                  </Disclosure>
                </article>
              </li>
            </ul>
          </div>
        </div>
        </div>

      </main>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ref } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/solid'
import { XIcon } from '@heroicons/vue/outline'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import moment from 'moment'

const fb = require('../firebaseConfig.js')

const statusStyles = {
  Positive: 'bg-green-100 text-green-800',
  Neutral: 'bg-yellow-100 text-yellow-800',
  Negative: 'bg-red-100 text-red-800',
  uncategorized: 'bg-gray-100 text-gray-800'
}

export default {
  setup () {
    const isOpen = ref(true)
    return {
      statusStyles,
      isOpen,
      setIsOpen (value) {
        isOpen.value = value
      }
    }
  },
  components: {
    InformationCircleIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon
  },
  data () {
    return {
      email: '',
      showSuccess: false,
      errorMsg: ''
      // filter: ''
    }
  },
  created: function () {
    this.moment = moment
    this.createdTime = Date.now()
  },
  mounted () {
    window.analytics.page('Dashboard')
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'currentAccount', 'responses']),
    greeting () {
      const myDate = new Date()
      const hrs = myDate.getHours()

      let greet

      if (hrs < 12) { greet = 'Good morning' } else if (hrs >= 12 && hrs <= 17) { greet = 'Good afternoon' } else if (hrs >= 17 && hrs <= 24) { greet = 'Good evening' }
      return greet
    },
    widgetUrl () {
      return window.location.origin + '/widget.js'
    },
    filteredResponses () {
      let allResponses = this.responses

      // if (this.filter) {
      //   console.log(filter)
      // } else {
      //   // filteredResponses = this.responses
      // }

      if (!allResponses.length) {
        allResponses = [{
          text: 'This is what your customer feedback will look like once you integrate the widget into your website! Every response will be categorized by sentiment and includes helpful information about the user and their browser.',
          sentiment: {
            label: 'uncategorized'
          },
          created: this.createdTime,
          id: this.createdTime,
          browserData: {
            appName: window.navigator.appName,
            appVersion: window.navigator.appVersion,
            userAgent: window.navigator.userAgent
          },
          routeData: window.location.href
        }]
      }

      return allResponses
    }
  },
  methods: {
    sendEmail () {
      const email = this.email
      const name = this.userProfile.name
      const appId = this.currentAccount.appId

      fb.notificationsCollection.add({
        to: email,
        template: {
          name: 'DeveloperInstructions',
          data: {
            name: name,
            appId: appId
          }
        }
      }).then(() => {
        this.showSuccess = true
        this.email = ''
        setTimeout(() => { this.showSuccess = false }, 2000)
      }).catch(err => {
        console.log(err)
        this.errorMsg = err.message
      })
    },
    setDeviceData (browserString) {
      // var nVer = browserString.appVersion
      var nAgt = browserString.userAgent
      var browserName = browserString.appName
      var fullVersion = '' + parseFloat(browserString.appVersion)
      var majorVersion = parseInt(browserString.appVersion, 10)
      var nameOffset, verOffset, ix
      if ((verOffset = nAgt.indexOf('OPR/')) !== -1) { // In Opera 15+, the true version is after "OPR/"
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 4)
      } else if ((verOffset = nAgt.indexOf('Opera')) !== -1) { // In older Opera, the true version is after "Opera" or after "Version"
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 6)
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
          fullVersion = nAgt.substring(verOffset + 8)
        }
      } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) { // In MSIE, the true version is after "MSIE" in userAgent
        browserName = 'Microsoft Internet Explorer'
        fullVersion = nAgt.substring(verOffset + 5)
      } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) { // In Chrome, the true version is after "Chrome"
        browserName = 'Chrome'
        fullVersion = nAgt.substring(verOffset + 7)
      } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) { // In Safari, the true version is after "Safari" or after "Version"
        browserName = 'Safari'
        fullVersion = nAgt.substring(verOffset + 7)
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
          fullVersion = nAgt.substring(verOffset + 8)
        }
      } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) { // In Firefox, the true version is after "Firefox"
        browserName = 'Firefox'
        fullVersion = nAgt.substring(verOffset + 8)
      } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        // In most other browsers, "name/version" is at the end of userAgent
        browserName = nAgt.substring(nameOffset, verOffset)
        fullVersion = nAgt.substring(verOffset + 1)
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
          browserName = browserString.appName
        }
      }
      // trim the fullVersion string at semicolon/space if present
      if ((ix = fullVersion.indexOf(';')) !== -1) {
        fullVersion = fullVersion.substring(0, ix)
      }
      if ((ix = fullVersion.indexOf(' ')) !== -1) {
        fullVersion = fullVersion.substring(0, ix)
      }

      majorVersion = parseInt('' + fullVersion, 10)
      if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(browserString.appVersion)
        majorVersion = parseInt(browserString.appVersion, 10)
      }

      return browserName + ' ' + fullVersion
    }
  }
}
</script>

<style>
  [v-cloak] {
    display: none;
  }
</style>
