import { createApp } from 'vue'
import { store } from './store'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
const fb = require('./firebaseConfig.js')

let app

try {
  fb.auth.onAuthStateChanged(user => {
    if (!app) {
      app = createApp(App).use(router).use(store).mount('#app')
    }

    if (user) {
      store.commit('setCurrentUser', user)
      store.dispatch('fetchUserProfile')
      // Identify user
      window.analytics.identify(user.uid, {
        email: user.email
      })
    }
  })
} catch (error) {
  console.log(error)
}
