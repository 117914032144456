// import Vue from 'vue'
import { createStore } from 'vuex'
import router from '../router'
const fb = require('./../firebaseConfig.js')

// Vue.use(Vuex)

export const store = new createStore({
  state: {
    currentUser: null,
    userProfile: null,
    userAccounts: [],
    currentAccount: {},
    responses: []
  },
  mutations: {
    setCurrentUser (state, val) {
      state.currentUser = val
    },
    setUserProfile (state, val) {
      state.userProfile = val
    },
    setUserAccounts (state, val) {
      state.userAccounts = val
    },
    setCurrentAccount (state, val) {
      state.currentAccount = val
    },
    setResponses (state, val) {
      state.responses = val
    }
  },
  actions: {
    clearData ({ commit }) {
      commit('setCurrentUser', null)
      commit('setUserProfile', null)
      commit('setUserAccounts', null)
      commit('setResponses', null)
    },
    fetchUserAccounts ({ dispatch, commit, state }) {
      fb.accountsCollection.where('appId', 'in', state.userProfile.accounts)
        .get()
        .then(function (querySnapshot) {
          const accountsArray = []

          querySnapshot.forEach(doc => {
            const account = doc.data()
            account.id = doc.id
            accountsArray.push(account)
          })

          console.log(accountsArray)
          commit('setUserAccounts', accountsArray)
          dispatch('fetchCurrentAccount', accountsArray[0].id)
        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchCurrentAccount ({ dispatch, commit }, id) {
      console.log('Account ID:', id)
      fb.accountsCollection.doc(id)
        .get()
        .then((doc) => {
          const currentAccount = doc.data()
          currentAccount.id = doc.id
          commit('setCurrentAccount', currentAccount)
          dispatch('fetchResponses')
        }).catch(err => {
          console.log(err)
        })
    },
    fetchUserProfile ({ commit, state }) {
      fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
        commit('setUserProfile', res.data())
        store.dispatch('fetchUserAccounts')

        // change route to dashboard
        if (router.currentRoute.path === '/login') {
          router.push('/')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    updateProfile ({ dispatch, state }, data) {
      const name = data.name
      fb.usersCollection.doc(state.currentUser.uid).update({ name })
        .then(() => {
          dispatch('fetchUserProfile')
        }).catch(err => {
          console.log(err)
        })
    },
    fetchResponses ({ commit, state }) {
      const pageSize = 25
      fb.accountsCollection.doc(state.currentAccount.id).collection('responses').orderBy('created', 'desc').limit(pageSize)
        .get()
        .then(function (querySnapshot) {
          const responsesArray = []
          querySnapshot.forEach(doc => {
            const response = doc.data()
            response.id = doc.id

            responsesArray.push(response)
          })

          commit('setResponses', responsesArray)

          // Listen for real-time updates to insights for this workspace
          fb.accountsCollection.doc(state.currentAccount.id).collection('responses').orderBy('created', 'desc').onSnapshot(querySnapshot => {
            const responsesArray = []
            querySnapshot.forEach(doc => {
              const response = doc.data()
              response.id = doc.id

              responsesArray.push(response)
            })

            commit('setResponses', responsesArray)
          })
        }).catch(err => {
          console.log(err)
        })
    }

  },
  modules: {}
})
